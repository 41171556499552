module.exports = {
  storeReports: [
    { name: "Store Status", route: "StoreStatus" },
    { name: "Receive Report", route: "ReceiveReport" },
    {
      name: "Transfer Report",
      route: "TransferReport",
      query: { origin: "main-store" },
    },
    { name: "Purchases By Items", route: "ItemPurchaseReport" },
    { name: "Transfer By Items", route: "ItemTransferReport" },
    { name: "Purchase Orders", route: "OrdersReports" },
    { name: "Spoiled Items Reports", route: "SpoiledItemsReport" },
    { name: "Dispatch Reports", route: "DispatchReport" },
    { name: "Returns Report", route: "ReturnsReport" },
    { name: "Price changing Report", route: "PriceChanging" },
  ],
  production: [
    { name: "Store Status", route: "ProductionStoreStatus" },
    {
      name: "Stock Receives",
      route: "ReceiveReport",
      query: { stock: "production" },
    },
    {
      name: "Transfer Report",
      route: "TransferReport",
      query: { origin: "production" },
    },
    { name: "Produced Items", route: "ProducedItemsReport" },
    { name: "Sales Report", route: "ProductionSalesReport" },
    { name: "Sales By Customer", route: "SalesByCustomer" },
    { name: "Customers Balance", route: "CustomerBalance" },
    { name: "Sales By Items", route: "ItemSalesReport" },
    {
      name: "Spoiled Items",
      route: "SpoiledItemsReport",
      query: { "items-category": "production" },
    },
    { name: "Payments Received", route: "PaymentsReceived" },
    {
      name: "Dispatch Reports",
      route: "DispatchReport",
      query: { origin: "production" },
    },
    { name: "Recipes Costing Report", route: "ProductionRecipesCosting" },
    { name: "Consumption Report", route: "ConsumptionReport" },
    {
      name: "Returns Report",
      route: "ReturnsReport",
      query: { origin: "production" },
    },
  ],
  financeReports: [
    { name: "Supplier Balance", route: "SupplierBalance" },
    { name: "Payments Made", route: "SupplierPaymentsMade" },
    { name: "Expenses", route: "Expenses" },
    { name: "Account Transactions", route: "AccountTransactions" },
    { name: "Accounts Transfers", route: "AccountsTransfers" },
    { name: "Profit and Loss", route: "ProfitLossReport" },
    { name: "Assets & Equipments", route: "AssetsListing" },
    { name: "Assets Stock", route: "AssetsStock" },
    { name: "Balance Sheet", route: "BalanceSheet" },
    { name: "Taken Debts", route: "DebtsReport" },
    { name: "Day To Day Debts", route: "DayToDayDebts" },
    { name: "Paid After Report", route: "PaidAfter" },
    { name: "General Ledger", route: "GeneralLedger" },
    { name: "Account Type Summary", route: "AccountTypeSummary" },
    { name: "Journals", route: "JournalsReport" },
    { name: "TrialBalance", route: "TrialBalance" },
  ],
  branchReports: [
    {
      name: "Store Status Report",
      route: "BranchStoreStatus",
      //for_branch: true,
    },
    {
      name: "Receiving Report",
      route: "BranchReceingReport",
      for_branch: true,
    },
    {
      name: "Purchase Report",
      route: "PurchasesReport",
    },
    { name: "Sales Report", route: "BranchesSalesReport" },
    { name: "Sales By Items", route: "BranchItemSalesReport" },
    { name: "Recipes Costing Report", route: "RecipesCosting" },
    { name: "Sales By Customer", route: "SalesByCustomer" },
    { name: "Customers Balance", route: "BranchCustomerBalance" },
    {
      name: "Payments Received",
      route: "PaymentsReceived",
      query: { category: "branch-sales" },
    },
    { name: "Sales Analysis Report", route: "SalesAnalysisReport" },
    {
      name: "Expenses Report",
      route: "Expenses",
      for_branch: true,
    },
    {
      name: "Spoiled Items Report",
      route: "SpoiledItemsReport",
      for_branch: true,
    },
    {
      name: "Transfer Report",
      route: "TransferReport",
      query: { origin: "branch" },
    },
    {
      name: "Account Transactions",
      route: "AccountTransactions",
      for_branch: true,
    },
    {
      name: "Returns Report",
      route: "ReturnsReport",
      query: { origin: "branch" },
      for_branch: true,
    },
  ],
  activityReports: [
    { name: "Activity Logs", route: "ActivityLogs" },
    { name: "Log Activity", route: "LoginActivities" },
  ],
};
