export const menus = [
  {
    title: "dashboard",
    icon: "ni ni-dashboard-fill",
    url: { name: "DashboardPage" },
    notifications: [],
  },
  {
    title: "inventory",
    icon: "ni ni-movie",
    submenus: {
      items: { name: "Products" },
      initialize_quantity: { name: "ProductsInitializor" },
      receive_items: { name: "ProductReceive" },
      requisitions: { name: "Requests" },
      stock_transfer: { name: "StockDelivery" },
      purchase_orders: { name: "PurchaseOrders" },
      spoiled_items: { name: "Spoiled" },
      adjustments: { name: "InventoryAdjustments" },
      auto_requisitions: {
        name: "Requests",
        query: { category: "auto", origin: "main-stock" },
      },
    },
    notifications: ["requisitions", "purchase_orders"],
  },
  {
    title: "production",
    icon: "ni ni-blogger-fill",
    submenus: {
      status: { name: "ProductionStatus" },
      items: { name: "ProductionItems" },
      make_production: { name: "ProductionMake" },
      invoices: { name: "ProductionInvoices" },
      receive_items: { name: "ProductionReceive" },
      stock_transfer: { name: "ProductionTransfer" },
      spoiled_items: { name: "ProductionSpoiled" },
      branches_requests: { name: "ProductionRequisitions" },
      adjustments: { name: "ProductionAdjustments" },
      placed_requisitions: { name: "RawMeterialsRequests" },
      auto_requisitions: {
        name: "ProductionRequisitions",
        query: { category: "auto", origin: "production" },
      },
      stock_return: {
        name: "ProductionReturnCreator",
        params: { action: "new" },
      },
      ingredients_consumption: { name: "IngredientsConsumption" },
    },
    notifications: [],
  },
  {
    title: "branches",
    icon: "ni ni-cc-alt2",
    submenus: {
      branches_list: { name: "Branches" },
      sales_items: { name: "BranchItems" },
      create_sales_item: { name: "BranchItemsCreator" },
      _auto_requisitions: { name: "AutoRequisitions" },
      invoices: { name: "BranchSalesInvoices" },
      purchases: { name: "PurchaseCreator" },
      adjustments: { name: "BranchAdjustments" },
      stock_transfer: { name: "BranchTransfer" },
      stock_return: { name: "ReturnCreator", params: { action: "new" } },
      physical_count: { name: "StockCount" },
    },
    notifications: [],
  },
  {
    title: "leads",
    icon: "ni ni-users-fill",
    submenus: {
      users: { name: "Users" },
      suppliers: { name: "Suppliers" },
      clients: { name: "Clients" },
    },
    notifications: [],
  },
  {
    title: "finance",
    icon: "ni ni-reports",
    submenus: {
      suppliers_payment: { name: "SupplierPayments" },
      bills: { name: "Bills" },
      expenses: { name: "ExpenseCreator", params: { action: "new" } },
      chart_of_accounts: { name: "PaymentAccounts" },
      banking: { name: "Banking" },
      manual_journals: { name: "ManualJournals", params: { action: "new" } },
    },
    notifications: ["suppliers_payments"],
  },
  {
    title: "reports",
    icon: "ni ni-growth-fill",
    url: { name: "GeneralReports" },
    notifications: ["pending_orders"],
  },
  {
    title: "settings",
    icon: "ni ni-setting",
    submenus: {
      general: { name: "Settings" },
      user_roles: { name: "UserRoles" },
      product_types: { name: "Categories" },
      products_units: { name: "Units" },
      payment_modes: { name: "PaymentModes" },
      production_sources: { name: "ProductionSource" },
      account_types: { name: "PaymentAccountsTypes" },
    },
    notifications: [],
  },
];
