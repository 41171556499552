<template>
  <div class="nk-header nk-header-fixed is-light border-left">
    <div class="nk-header-wrap">
      <div
        class="dropdown dropdown-mega d-none d-lg-block"
        v-if="
          reportRoutes.includes(currentRoute) || currentRoute === 'ReportsIndex'
        "
      >
        <button
          type="button"
          class="btn header-ite text-white dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          <span>
            <svg
              id="Layer_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 459.1 512"
              class="icon lpanel"
              style="
                margin-right: 4px;
                margin-left: 2px;
                opacity: 1;
                fill: #fff;
                vertical-align: text-bottom;
                width: 16px;
                height: 16px;
              "
            >
              <g id="Layer_1-2">
                <path
                  d="M382.4 412.6c11 0 20-9 20-20V20c0-11-9-20-20-20s-20 9-20 20v372.6c0 11 9 20 20 20zM77.7 412.6c11 0 20-9 20-20V256c0-11-9-20-20-20s-20 9-20 20v136.6c0 11 9 20 20 20zM229.1 412.6c11 0 20-9 20-20V133.9c0-11-9-20-20-20s-20 9-20 20v258.6c0 11 9 20 20 20zM439.1 472H20c-11 0-20 9-20 20s9 20 20 20h419.1c11 0 20-9 20-20s-9-20-20-20z"
                ></path>
              </g>
            </svg>
            <span class="fw-bold">Reports</span></span
          >
        </button>
        <div class="dropdown-menu p-2 dropdown-megamenu mega-menu">
          <div class="orders-container">
            <div class="order-item">
              <h5 class="font-size-14 mx-2 mt-2 fw-bolder">Store Reports</h5>
              <div
                class="report-items"
                v-if="isGroupAccessible('store_reports')"
              >
                <template v-for="(item, i) in reports.storeReports">
                  <RouterLink
                    :to="{
                      name: item.route,
                      params: item.params,
                      query: item.query,
                    }"
                    v-if="
                      canView([
                        'store_reports',
                        $helper.createTitleSlug(item.name),
                      ])
                    "
                    class="dropdown-item"
                    :key="$helper.generateRandomText(7) + i"
                    >{{ item.name }}</RouterLink
                  >
                </template>
              </div>
            </div>

            <div
              class="order-item"
              v-if="isGroupAccessible('production_report')"
            >
              <h5 class="font-size-14 mx-2 mt-2 fw-bolder">
                Production Reports
              </h5>

              <div class="report-items">
                <template v-for="(item, i) in reports.production">
                  <RouterLink
                    :to="{
                      name: item.route,
                      params: item.params,
                      query: item.query,
                    }"
                    v-if="
                      canView([
                        'production_report',
                        $helper.createTitleSlug(item.name),
                      ])
                    "
                    class="dropdown-item"
                    :key="$helper.generateRandomText(7) + i"
                    >{{ item.name }}</RouterLink
                  >
                </template>
              </div>
            </div>

            <div class="order-item" v-if="isGroupAccessible('finance_reports')">
              <h5 class="font-size-14 mx-2 mt-2 fw-bolder">
                Financial Reports
              </h5>
              <div class="report-items">
                <template v-for="(item, i) in reports.financeReports">
                  <RouterLink
                    :to="{
                      name: item.route,
                      params: item.params,
                      query: item.query,
                    }"
                    v-if="
                      canView([
                        'finance_reports',
                        $helper.createTitleSlug(item.name),
                      ])
                    "
                    class="dropdown-item"
                    :key="$helper.generateRandomText(7) + i"
                    >{{ item.name }}</RouterLink
                  >
                </template>
              </div>
            </div>

            <div
              class="order-item"
              v-if="isGroupAccessible('branches_reports')"
            >
              <h5 class="font-size-14 mx-2 mt-2 fw-bolder">
                Branch Level Reports
              </h5>

              <div class="report-items">
                <template v-for="(item, i) in reports.branchReports">
                  <RouterLink
                    :to="{
                      name: item.route,
                      params: item.params,
                      query: item.query,
                    }"
                    v-if="
                      canView([
                        'branches_reports',
                        $helper.createTitleSlug(item.name),
                      ])
                    "
                    class="dropdown-item"
                    :key="$helper.generateRandomText(7) + i"
                    >{{ item.name }}</RouterLink
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="nk-header-search ml-3 ms-xl-0 border rounded bg-white"
        style="max-width: 300px; transition: width 0.3s ease-out"
      >
        <em
          class="icon ni ni-search"
          style="font-size: 1.2rem; margin-left: 5px"
        ></em
        ><input
          type="text"
          class="form-control border-dark form-focus-none"
          placeholder="Search anything"
          style="padding-left: 8px"
        />
      </div> -->
      <div class="nk-header-tools">
        <ul class="nk-quick-nav">
          <li class="dropdown d-none d-md-block">
            <a
              class="mr-n1 dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              aria-expanded="false"
            >
              <img
                :src="`/img/flags/${country.flag}`"
                class="img-fluid"
                style="width: 20px"
                :alt="country.label"
              />
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="link-list-opt no-bdr d-flex flex-column">
                <li
                  v-for="(item, i) in languages"
                  :key="'lang' + i"
                  :class="{ 'order-first': country.label == item.label }"
                >
                  <a
                    href="javascript:void(0)"
                    @click.prevent="setLanguage(item)"
                  >
                    <img
                      :src="`/img/flags/${item.flag}`"
                      class="img-fluid"
                      style="width: 20px"
                      :alt="item.label"
                    />
                    <span class="lang-label">{{ item.label }}</span></a
                  >
                </li>
              </ul>
            </div>
          </li>
          <li class="dropdown notification-dropdown" id="notification-dropdown">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle nk-quick-nav-icon"
              data-toggle="dropdown"
              aria-expanded="false"
              @click.prevent="showNotifications"
              ><div
                class="icon-status"
                :class="{ 'icon-status-info': hasNotifications }"
              >
                <em class="icon ni ni-bell"></em></div
            ></a>
            <div
              class="dropdown-menu dropdown-menu-xl dropdown-menu-right"
              style=""
              :class="{ show: notification }"
            >
              <div class="dropdown-head">
                <span class="sub-title nk-dropdown-title">Notifications</span
                ><a href="javascript:void(0)" v-show="false"
                  >Mark All as Read</a
                >
              </div>
              <div class="dropdown-body">
                <div class="nk-notification">
                  <a
                    class="nk-notification-item dropdown-inner"
                    v-for="(item, i) in notifications"
                    :key="'notification' + i"
                    :href="item.data.notification.link"
                  >
                    <div class="nk-notification-icon">
                      <em
                        :class="`icon icon-circle ${notificationIcon(
                          item.data.notification.event
                        )}
                          `"
                      ></em>
                    </div>
                    <div class="nk-notification-content">
                      <div class="nk-notification-text">
                        {{ item.data.notification.message }}
                      </div>
                      <div class="nk-notification-time">
                        {{ Helpers.timeDifference(new Date(item.created_at)) }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="dropdown-foot center">
                <a href="javascript:void(0)">View All</a>
              </div>
            </div>
          </li>
          <li class="d-none d-md-block">
            <div class="d-flex align-items-center">
              <div class="org-profile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon icon-sm fill-bluish-grey"
                >
                  <path
                    fill="#838195"
                    d="M426.2 1.1h-184c-35.8 0-65 29.2-65 65v70h-91c-35.8 0-65 29.2-65 65v265c0 24.8 20.2 45 45 45h380c24.8 0 45-20.2 45-45v-400c0-35.8-29.2-65-65-65zm15 460h-214v-395c0-8.3 6.7-15 15-15h184c8.3 0 15 6.7 15 15v395zm-370-260c0-8.3 6.7-15 15-15h91v275h-106v-260z"
                  ></path>
                </svg>
              </div>
              <div class="ml-1 d-block">
                <div
                  class="text-truncate d-block align-bottom fw-bold"
                  style="max-width: 280px"
                >
                  {{ appSettings.site_name || "Tame Apps" }}
                </div>
                <div class="font-xxs text-primary">
                  <span v-if="!$helper.empty(loggedUser.branch)"
                    >{{ loggedUser.branch.name }} <span>Branch</span></span
                  >
                  <span v-else-if="!$helper.empty(loggedUser.source)"
                    >{{ loggedUser.source.name }} <span>Branch</span></span
                  >
                  <span v-else>TIN: {{ appSettings.app_tin || "N/A" }}</span>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown user-dropdown mr-lg-5" id="user-dropdown">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle mr-n1"
              @click="userDropdown = !userDropdown"
              ><div class="user-toggle">
                <div class="user-avatar sm">
                  <avatar
                    :name="`${currentUser.first_name} ${
                      currentUser.last_name || ''
                    }`"
                  ></avatar>
                </div></div
            ></a>
            <div
              class="dropdown-menu dropdown-menu-md dropdown-menu-right"
              style=""
              :class="{ show: userDropdown }"
            >
              <div
                class="dropdown-inner user-card-wrap bg-lighter d-md-block p-2"
              >
                <div class="user-card">
                  <avatar
                    :name="`${currentUser.first_name} ${
                      currentUser.last_name || ''
                    }`"
                  ></avatar>
                  <div class="user-info">
                    <span class="lead-text">{{ `${currentUser.name}` }}</span
                    ><span class="sub-text">{{ userRole }}</span>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center dropdown-inner d-md-none pl-2 py-2"
              >
                <div class="org-profile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    class="icon icon-sm fill-bluish-grey"
                  >
                    <path
                      fill="#838195"
                      d="M426.2 1.1h-184c-35.8 0-65 29.2-65 65v70h-91c-35.8 0-65 29.2-65 65v265c0 24.8 20.2 45 45 45h380c24.8 0 45-20.2 45-45v-400c0-35.8-29.2-65-65-65zm15 460h-214v-395c0-8.3 6.7-15 15-15h184c8.3 0 15 6.7 15 15v395zm-370-260c0-8.3 6.7-15 15-15h91v275h-106v-260z"
                    ></path>
                  </svg>
                </div>
                <div class="ml-1 d-block">
                  <div
                    class="text-truncate d-block align-bottom fw-bold"
                    style="max-width: 280px"
                  >
                    {{ appSettings.site_name || "Tame Apps" }}
                  </div>
                  <div class="font-xxs text-primary">
                    <span v-if="!$helper.empty(loggedUser.branch)"
                      >{{ loggedUser.branch.name }} <span>Branch</span></span
                    >
                    <span v-else-if="!$helper.empty(loggedUser.source)"
                      >{{ loggedUser.source.name }} <span>Branch</span></span
                    >
                    <span v-else>TIN: {{ appSettings.app_tin || "N/A" }}</span>
                  </div>
                </div>
              </div>
              <div class="dropdown-inner">
                <ul class="link-list">
                  <li>
                    <router-link :to="{ name: 'AccountProfile' }"
                      ><em class="icon ni ni-user-alt"></em
                      ><span>View Profile</span></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'AccountSecurity' }"
                      ><em class="icon ni ni-setting-alt"></em
                      ><span>Account Setting</span></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'LoginHistory' }"
                      ><em class="icon ni ni-activity-alt"></em
                      ><span>Login History</span></router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown-inner">
                <ul class="link-list">
                  <li>
                    <a href="javascript:void(0)" @click.prevent="signout"
                      ><em class="icon ni ni-signout"></em
                      ><span>Sign out</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <branch-selector v-if="modalOpen" @closed="toggleModal"></branch-selector>
  </div>
</template>
<script>
import Avatar from "@/components/Avatar.vue";
import { mapState } from "vuex";
import BranchSelector from "./BranchSelector.vue";
import { Helpers } from "@/Helpers";
import { languages } from "@/lang/languages";
import { setLanguage, getCountry } from "@/lang/index";
import { reportsMixin } from "@/_helpers/global-mixin.js";
export default {
  name: "NavBar",
  mixins: [reportsMixin],
  components: {
    Avatar,
    BranchSelector,
  },
  data: () => ({
    userDropdown: false,
    notification: false,
    branchesDropdown: false,
    hasReadAll: false,
    Helpers,
    languages,
    country: {},
    currentRoute: "",
  }),
  computed: {
    ...mapState({
      lockedBranch: (state) => state.branch,
    }),
    currentUser() {
      return this.$store.getters.user;
    },
    userRole() {
      if (this.currentUser.role) return this.currentUser.role.name;
      else return "";
    },
    notifications() {
      return this.currentUser.alerts || [];
    },
    hasNotifications() {
      return (
        this.notifications.some((item) => item.read_at == null) &&
        !this.hasReadAll
      );
    },
    reportRoutes() {
      const reports = Object.values(this.reports)
        .flat()
        .map((item) => item.route);
      return reports;
    },
  },
  mounted() {
    this.country = getCountry();
  },
  watch: {
    $route(to) {
      this.currentRoute = to.name;
    },
  },
  methods: {
    setLanguage,
    showNotifications() {
      this.notification = !this.notification;
      if (this.hasNotifications) {
        this.$store.commit("SET_REQUEST_FLAG", "READING_NOTIFICATIONS");
        this.$http.get("auth/notifications/read").then(() => {
          this.hasReadAll = true;
        });
      }
    },
    toggleSidebar() {
      console.log("Tested");

      this.$store.commit("TOGGLE_SIDEBAR");
    },
    notificationIcon(event) {
      var result;
      switch (event) {
        case "StockTransfer":
          result = "bg-success ni-building-fill";
          break;
        case "RequisitionUpdated":
          result = "bg-primary-dim ni-reload";
          break;
        default:
          result = "bg-primary ni ni-trend-up";
      }
      return result;
    },
    signout() {
      this.$http
        .post("auth/logout", this.$helper.generateFormData({}))
        .then(() => {
          this.$store.dispatch("logout").then(() => {
            window.location.replace("/");
          });
        });
    },
  },
};
</script>
<style scoped>
.org-profile {
  height: 38px;
  width: 38px;
  border: 1px solid #dfdfeb;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-item {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.order-item > .order-card {
  grid-row: 1 / -1;
  grid-column: 1;
}

.orders-container {
  column-count: 4;
  column-gap: 10px;
}
@media (min-width: 992px) {
  .orders-container {
    column-count: 4;
  }
}
</style>
