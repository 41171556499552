import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VeeValidate from "vee-validate";
import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { globalMixin } from "./_helpers/global-mixin.js";
import helper from "./_helpers/index.js";

import CustomButton from "@/components/CustomButton.vue";
import alert from "@/components/alert.js";
import notifier from "@/components/notifier.js";
import { getLanguage } from "@/lang";
import { encodeQuery, baseURL } from "@/_helpers/utils.js";

/**
 * For confirm example
 * this.$confirm({
        title: "test",
        content: "are you sure?",
        actionText: "Save",
        closeText: "Cancel",
        classes: "btn btn-primary",
        action: () => {
          // handle action
          console.log("Tested");
        },
      });
 */
axios.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    const branch = localStorage.getItem("__branch");
    if (branch) {
      config.url = encodeQuery(config.url, {
        current_branch: JSON.parse(branch).id,
      });
    }
    NProgress.start();
    store.state.isLoading = true;
    return config;
  },
  (error) => {
    NProgress.done();
    store.commit("REQUEST_RESOLVED");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    store.commit("REQUEST_RESOLVED");
    return response;
  },
  (error) => {
    NProgress.done();
    store.commit("REQUEST_RESOLVED");
    if (error.response.status === 401 && location.pathname != "#/") {
      console.log('Here');
      
      localStorage.removeItem("token");
      location.replace("/");
    }
    if (error.response.status == 500) {
      store.commit("SET_FLASH_MESSAGE", {
        type: "danger",
        message: "Error Occured. Please contact system administrator",
      });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = baseURL;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

const options = {
  latencyThreshold: 200,
  router: true,
  http: true,
};

Vue.use(NProgress, options);
Vue.use(VeeValidate);
Vue.use(alert, { store });
Vue.use(notifier);
Vue.use(helper);
// Setting up current language
const lang = getLanguage();
const trans = (text) => {
  return lang[text.trim()] || text;
};
Vue.filter("trans", trans);

Vue.mixin(globalMixin);
Vue.component("custom-button", CustomButton);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
