import axios from "axios";

export const encodeQuery = (url, data) => {
  let query = "";
  for (let d in data) {
    if (data[d] && url.indexOf(`?${d}`) < 0 && url.indexOf(`&${d}`) < 0)
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
  }
  return url.indexOf("?") > -1
    ? `${url}&${query.slice(0, -1)}`
    : `${url}?${query.slice(0, -1)}`;
};

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/inventory/"
    : "http://127.0.0.1:8000/api/inventory/";

export const getURIParams = () => {
  return location.search
    .substring(1)
    .split("&")
    .map((v) => v.split("="))
    .reduce((obj, [key, value]) => {
      obj[key] = decodeURIComponent(value);
      return obj;
    }, Object.create(null));
};

export const uploadsUrl =
  process.env.NODE_ENV === "production"
    ? "/api/uploads/"
    : "http://127.0.0.1:8000/uploads/";

export const exportToExcel = (node, callback = null) => {
  node = document.querySelector(node);
  const tableHtml = node.outerHTML;
  const blob = new Blob([tableHtml], { type: "text/html" });
  const formData = new FormData();
  formData.append("htmlFile", blob, "table.html");
  const url =
    process.env.NODE_ENV === "production"
      ? `${window.location.origin}/api/`
      : "http://127.0.0.1:8000/api/";
  const axiosInstance = axios.create({
    baseURL: url,
  });
  axiosInstance
    .post(`${url}exports/excel`, formData, {
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (typeof callback === "function") {
        callback();
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(response.data);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${document.title}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
};
